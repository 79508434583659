.root {
    .headerTop {
        display: flex;
        justify-content: space-between;
        background-color: #ffc107;
        height: 60px;
        & > div {
            display: flex;
            & > * {
                margin: 0 10px;
            }
        }
    }

    .branding {
        width: 180px;
        height: 60px;
        font-size: 35px;
        font-weight: 800;
        color: #fff;
        line-height: 60px;
        text-align: center;
    }

    //     root: {
    //         flexGrow: 1,
    //     },
    //     linkContainer: {
    //         textAlign: 'center',
    //     },
    //     link: {
    //         textDecoration: 'none',
    //         textTransform: 'uppercase',
    //         color: 'initial',
    //     },
    //     paper: {
    //         padding: theme.spacing(2),
    //         textAlign: 'center',
    //         color: theme.palette.text.secondary,
    //         height: '100%',
    //     },

    //     menuIcon: {
    //         height: '60px',
    //         width: '40px',
    //         color: '#fff',
    //         cursor: 'pointer',
    //     },
}
